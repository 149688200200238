<template>
  <el-main>
    <div>
      <el-breadcrumb
          separator-class="el-icon-arrow-right"
          v-if="this.$router.currentRoute.path != '/home'"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/coalManage' }"
        >项目管理</el-breadcrumb-item
        >
      </el-breadcrumb>

      <span style="font-size: 12px">管理概述：</span>
      <div class="coalblock" v-for="item in options" :key="item.title">
        <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>
        <!-- <br /> -->
        <span style="font-size: 24px;margin-left: 10px;">{{ item.num }}</span>
      </div>
      <el-form :inline="true">
<!--        v-if="setting.backups"-->
        <el-form-item>
          <el-button v-if="isAuth('coal:add') && setting.backups" type="primary" @click="systemDialog = true">增加项目</el-button>
          <!-- addcoal() -->
          <!--          <el-button-->
          <!--            type="danger"-->
          <!--            :disabled="multipleSelection.length == 0"-->
          <!--            @click="deleteMany()"-->
          <!--            >批量删除项目</el-button-->
          <!--          >-->
        </el-form-item>
        <span style="font-size: 16px" v-if="isAuth('coal:list') && setting.advancesearch">搜索：</span>
        <el-form-item>
          <el-input
              v-if="isAuth('coal:list') && setting.advancesearch"
              v-model="coalName"
              placeholder="请输入要搜索的内容"
          ></el-input>
        </el-form-item>

        <!--        <el-form-item>-->
        <!--          <el-button>重置</el-button>-->
        <!--        </el-form-item>-->
        <el-form-item>
<!--          <el-input-->
<!--              v-model="coalAddress"-->
<!--              placeholder="请输入地址"-->
<!--          ></el-input>-->
          <el-cascader v-if="isAuth('coal:list') && setting.advancesearch" :options="areaSelectData" class="full-width" size="large" @change="handleChangeForQuery" :clearable="true" style="width: 300px; height: 32px"
                       filterable v-model="selectedOptionsForQuery" placeholder="请选择地址" />
<!--          <el-select-->
<!--              v-model="coalAddress"-->
<!--              placeholder="地址"-->
<!--              style="width: 150px"-->
<!--              :clearable="true"-->
<!--              v-if="setting.advancesearch"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="item in coaloptions"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.label"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <el-select-->
<!--              v-model="disasterType"-->
<!--              placeholder="灾害类型"-->
<!--              style="margin-left: 10px; width: 150px"-->
<!--              :clearable="true"-->
<!--              v-if="setting.advancesearch"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="item in addressoptions"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.label"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-input-->
<!--              v-if="isAuth('coal:list') && setting.advancesearch"-->
<!--              v-model="disasterType"-->
<!--              placeholder="请输入灾害"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button v-if="isAuth('coal:list') && setting.advancesearch" type="primary" @click="queryByCondition()">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table

          :data="tableData"
          @selection-change="handleSelectionChange"
          style="min-width: 100%"
          border
          v-loading="dataListLoading"
      >
        <el-table-column
            v-if="setting.backups"
            type="selection"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            label="序号"
            type="index"
            min-width="5%"
            align="center">
          <template slot-scope="scope">
            <span>{{(page - 1) * size + scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="id"
            label="id"
            header-align="center"
            align="center"
            min-width="5%"
            v-if="false"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="名称"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            prop="systemCount"
            label="系统个数"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            prop="address"
            label="地址"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            prop="yield"
            label="产量"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            prop="miningMethod"
            label="煤层埋深"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            prop="disasterType"
            label="煤层倾角"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            prop="createDate"
            label="创建时间"
            header-align="center"
            align="center"
            min-width="10%"
        >
        </el-table-column>
        <el-table-column
            label="操作"
            header-align="center"
            align="center"
            min-width="10%"
            fixed="right"
        >
          <template slot-scope="scope">
            <el-button
                v-if="isAuth('coal:select')"
                type="text"
                size="small"
                @click="checkCoal(scope.row.id)"
            >查看</el-button
            >
            <el-button
                v-if="isAuth('coal:update')"
                type="text"
                size="small"
                @click="updateHandle(scope.row)"
            >修改</el-button>
            <el-button
                v-if="isAuth('coal:delete')"
                type="text"
                size="small"
                style="color: red"
                @click="deleteCoal(scope.row.id)"
            >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注"
            header-align="center"
            align="center"
            min-width="15%"
        >
        </el-table-column>
      </el-table>

      <update-coal
          v-if="updateVisible"
          ref="updateCoal"
          @refreshDataList="initData"
      ></update-coal>

      <!-- 分页区域
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[1, 2, 5, 10]" :page-size="1" layout="total, sizes, prev, pager, next, jumper" :total="4">
              </el-pagination>-->
      <div style="display: flex; justify-content: flex-end">
        <el-pagination
            background
            @current-change="currentChange"
            @size-change="sizeChange"
            :current-page="page"
            :page-size="size"
            layout="sizes, prev, pager, next, jumper, ->, total, slot"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div>
      <checkCoal v-if="checkCoalDialog" ref="coalInfo"></checkCoal>
    </div>
    <div>
      <el-dialog
          title="新建项目"
          :close-on-click-modal="false"
          :visible.sync="systemDialog"
          width="60%"
          append-to-body
      >
        <el-steps
            :space="200"
            :active="activeIndex - 0"
            finish-status="success"
            align-center
        >
          <el-step title="项目信息"></el-step>
          <el-step title="项目系统"></el-step>
          <el-step title="子系统"></el-step>
          <el-step title="设备"></el-step>
          <el-step title="附属设备"></el-step>
          <el-step title="数据项"></el-step>
          <el-step title="确认"></el-step>
        </el-steps>
        <el-form
            :model="addForm"
            :rules="rules"
            ref="addForm"
            :label-position="addFormLabelPosition"
            label-width="100px"
        >
          <el-tabs :tab-position="'left'" v-model="activeIndex">
            <el-tab-pane label="项目信息" name="0">
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item label="新项目名称" prop="coalName">
                    <el-input
                        style="width: 66%"
                        v-model="addForm.coalName"
                        placeholder="请输入系统名称"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址信息" prop="address">
<!--                    <el-input-->
<!--                        style="width: 66%"-->
<!--                        v-model="addForm.address"-->
<!--                        placeholder="请输入内容"-->
<!--                    ></el-input>-->
                    <el-cascader :options="areaSelectData" class="full-width" size="large" @change="handleChange"
                                 v-model="selectedOptions" placeholder="请选择您所在的城市" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="产量信息" prop="output">
                    <el-input
                        style="width: 66%"
                        v-model="addForm.output"
                        placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item label="煤质信息" prop="quality">
                    <el-input
                        style="width: 66%"
                        v-model="addForm.quality"
                        placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="煤层埋深" prop="miningMethod">
                    <el-input
                        style="width: 66%"
                        v-model="addForm.miningMethod"
                        placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="煤层倾角" prop="disaster">
                    <el-input
                        style="width: 66%"
                        v-model="addForm.disaster"
                        placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item label="服务年限" prop="serviceNum">
                    <el-input
                        style="width: 66%"
                        v-model="addForm.serviceNum"
                        placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="摺曲影响" prop="foldImpact">
                    <el-select v-model="addForm.foldImpact" placeholder="请选择">
                      <el-option
                          v-for="item in foldImpactOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="断层影响" prop="faultImpact">
                    <el-select v-model="addForm.faultImpact" placeholder="请选择">
                      <el-option
                          v-for="item in faultImpactOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item label="陷落柱影响" prop="trapColumnImpact">
                    <el-select v-model="addForm.trapColumnImpact" placeholder="请选择">
                      <el-option
                          v-for="item in trapColumnImpactOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="围岩稳定性" prop="surroundingRockStability">
                    <el-select v-model="addForm.surroundingRockStability" placeholder="请选择">
                      <el-option
                          v-for="item in surroundingRockStabilityOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="煤层自燃倾向性" prop="seamSpontaneousCombustionPropensity">
                    <el-select v-model="addForm.seamSpontaneousCombustionPropensity" placeholder="请选择">
                      <el-option
                          v-for="item in seamSpontaneousCombustionPropensityOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item label="瓦斯等级" prop="gasGrade">
                    <el-select v-model="addForm.gasGrade" placeholder="请选择">
                      <el-option
                          v-for="item in gasGradeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="矿井水文地质条件" prop="mineConditionsHydrogeological">
                    <el-select v-model="addForm.mineConditionsHydrogeological" placeholder="请选择">
                      <el-option
                          v-for="item in mineConditionsHydrogeologicalOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="冲击倾向性" prop="impactPropensity">
                    <el-select v-model="addForm.impactPropensity" placeholder="请选择">
                      <el-option
                          v-for="item in impactPropensityOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item label="煤尘爆炸危险性" prop="coalDustExplosionRisk">
                    <el-select v-model="addForm.coalDustExplosionRisk" placeholder="请选择">
                      <el-option
                          v-for="item in coalDustExplosionRiskOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="热害等级" prop="heatDamageGrade">
                    <el-select v-model="addForm.heatDamageGrade" placeholder="请选择">
                      <el-option
                          v-for="item in heatDamageGradeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="项目系统" name="1">
              <el-form-item label="添加系统">
                <div
                    v-for="systemName in addForm.systemName"
                    :key="systemName.id"
                >
                  <el-input
                      style="width: 45%; margin-bottom: 5px"
                      v-model="systemName.value"
                      placeholder="请输入系统名称"
                      @keyup.native="
                      updateSystemOptions(systemName.id, systemName.value, systemName.remark)
                    "
                  ></el-input>
                  <el-input
                      style="width: 45%; margin-bottom: 5px"
                      v-model="systemName.remark"
                      placeholder="添加系统描述"
                      @keyup.native="
                      updateSystemOptions(systemName.id, systemName.value, systemName.remark)
                    "
                  >
                  </el-input>
                </div>
                <el-button
                    class="addSystemButton"
                    type="primary"
                    size="small"
                    @click="addSystemName"
                    round
                >+
                </el-button>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="子系统" name="2">
              <el-form-item label="添加子系统">
                <el-select
                    @change="systemChange(addForm.addSystemSelect)"
                    style="width: 91%; margin-bottom: 5px"
                    v-model="addForm.addSystemSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addSystemOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 91%; height: 100%; margin-bottom: 5px"
                    v-if="subSystemAlter"
                    :closable="false"
                    title="请选择系统"
                    type="error"
                    effect="dark"
                >
                </el-alert>
                <div
                    v-for="subsystemName in addForm.subsystemName"
                    :key="subsystemName.id"
                >
                  <el-input
                      @focus="subSystemFocus"
                      :disabled="subSystemDisabled"
                      style="width: 45%; margin-bottom: 5px"
                      v-model="subsystemName.value"
                      @keyup.native="
                      updateSubSystemOptions(
                        subsystemName.id,
                        subsystemName.value,
                        subsystemName.remark
                      )
                    "
                      placeholder="请输入子系统名称"
                  ></el-input>
                  <el-input
                      @focus="subSystemFocus"
                      :disabled="subSystemDisabled"
                      style="width: 45%; margin-bottom: 5px"
                      v-model="subsystemName.remark"
                      @keyup.native="
                      updateSubSystemOptions(
                        subsystemName.id,
                        subsystemName.value,
                        subsystemName.remark
                      )
                    "
                      placeholder="添加子系统描述"
                  ></el-input>
                </div>
                <el-button
                    class="addSystemButton"
                    type="primary"
                    size="small"
                    @click="addSubsystemName"
                    round
                >+
                </el-button>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="设备" name="3"
            ><el-form-item label="添加设备">
              <el-select
                  @change="
                    subSystemChange(
                      addForm.addSystemSelect,
                      addForm.addSubSystemSelect
                    )
                  "
                  style="width: 91%; margin-bottom: 5px"
                  v-model="addForm.addSubSystemSelect"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in addForm.addSubSystemOptions"
                    :key="item.id"
                    :value="item.value"
                >
                </el-option>
              </el-select>
              <el-alert
                  style="width: 91%; height: 100%; margin-bottom: 5px"
                  v-if="deviceAlter"
                  :closable="false"
                  title="请选择子系统"
                  type="error"
                  effect="dark"
              >
              </el-alert>
              <div v-for="device in addForm.device" :key="device.id">
                <el-input
                    @focus="deviceFocus"
                    :disabled="deviceDisabled"
                    style="width: 45%; margin-bottom: 5px"
                    v-model="device.value"
                    @keyup.native="updateDeviceOptions(device.id, device.value, device.remark)"
                    placeholder="请输入设备名称"
                ></el-input>
                <el-input
                    @focus="deviceFocus"
                    :disabled="deviceDisabled"
                    style="width: 45%; margin-bottom: 5px"
                    v-model="device.remark"
                    @keyup.native="updateDeviceOptions(device.id, device.value, device.remark)"
                    placeholder="添加设备描述"
                ></el-input>
              </div>
              <el-button
                  class="addSystemButton"
                  type="primary"
                  size="small"
                  @click="addDevice"
                  round
              >+</el-button
              >
            </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="附属设备" name="4">
              <el-form-item label="添加附属设备">
                <el-select
                    @change="
                    deviceChange(
                      addForm.addSystemSelect,
                      addForm.addSubSystemSelect,
                      addForm.addDeviceSelect
                    )
                  "
                    style="width: 91%; margin-bottom: 5px"
                    v-model="addForm.addDeviceSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addDeviceOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 91%; height: 100%; margin-bottom: 5px"
                    v-if="subDeviceAlter"
                    :closable="false"
                    title="请选择子系统"
                    type="error"
                    effect="dark"
                >
                </el-alert>
                <div v-for="subDevice in addForm.subDevice" :key="subDevice.id">
                  <el-input
                      @focus="subDeviceFocus"
                      :disabled="subDeviceDisabled"
                      style="width: 45%; margin-bottom: 5px"
                      v-model="subDevice.value"
                      @keyup.native="
                      updateSubDeviceOptions(subDevice.id, subDevice.value, subDevice.remark)
                    "
                      placeholder="请输入附属设备名称"
                  ></el-input>
                  <el-input
                      @focus="subDeviceFocus"
                      :disabled="subDeviceDisabled"
                      style="width: 45%; margin-bottom: 5px"
                      v-model="subDevice.remark"
                      placeholder="添加附属设备描述"
                      @keyup.native="
                      updateSubDeviceOptions(subDevice.id, subDevice.value, subDevice.remark)
                    "
                  ></el-input>
                </div>
                <el-button
                    class="addSystemButton"
                    type="primary"
                    size="small"
                    @click="addSubDevice"
                    round
                >+</el-button
                >
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="数据项" name="5">
              <el-form-item label="添加数据项">
                <el-select
                    @change="
                    subDeviceChange(
                      addForm.addSystemSelect,
                      addForm.addSubSystemSelect,
                      addForm.addDeviceSelect,
                      addForm.addSubDeviceSelect
                    )
                  "
                    style="width: 91%; margin-bottom: 5px"
                    v-model="addForm.addSubDeviceSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addSubDeviceOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 91%; height: 100%; margin-bottom: 5px"
                    v-if="subDeviceFieldAlter"
                    :closable="false"
                    title="请选择附属设备"
                    type="error"
                    effect="dark"
                >
                </el-alert>
                <div
                    v-for="subDeviceField in addForm.subDeviceField"
                    :key="subDeviceField.id"
                >
                  <el-input
                      @focus="subDeviceFieldFocus"
                      :disabled="subDeviceFieldDisabled"
                      @keyup.native="
                      updateSubDeviceFieldOptions(
                        subDeviceField.id,
                        subDeviceField.value,
                        subDeviceField.remark,
                        subDeviceField.type,
                      )
                    "
                      style="width: 45%; margin-bottom: 5px"
                      v-model="subDeviceField.value"
                      placeholder="请输入数据项名称"
                  >
                  </el-input>
                  <el-input
                      @focus="subDeviceFieldFocus"
                      :disabled="subDeviceFieldDisabled"
                      style="width: 45%; margin-bottom: 5px"
                      v-model="subDeviceField.remark"
                      @keyup.native="
                      updateSubDeviceFieldOptions(
                        subDeviceField.id,
                        subDeviceField.value,
                        subDeviceField.remark,
                        subDeviceField.type,
                      )
                    "
                      placeholder="添加数据项描述"
                  >
                  </el-input>
<!--                  添加数据项类型-->
                  <el-form-item label="数据项数据类型" prop="type">
<!--                    <el-select v-model="addForm.type" placeholder="请选择">-->
                    <el-select v-model="subDeviceField.type" placeholder="请选择" @change="
                               updateSubDeviceFieldOptions(
                               subDeviceField.id,
                               subDeviceField.value,
                               subDeviceField.remark,
                               subDeviceField.type,
                     )
                    ">
                      <el-option
                              v-for="item in typeOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                </div>
                <el-button
                    class="addSystemButton"
                    type="primary"
                    size="small"
                    @click="addSubDeviceField"
                    round
                >+
                </el-button>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="确认" name="6">
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-form-item
                      label="新系统简介"
                      prop="remark"
                      style="margin-bottom: 10px"
                  >
                    <el-input
                        style="width: 100%"
                        type="textarea"
                        :rows="3"
                        v-model="addForm.remark"
                        placeholder="请输入简介信息"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="当前操作员" prop="currOperatorName" style="margin-bottom: 10px">
                <el-input style="width: 20%" class="small-input" v-model="addForm.currOperatorName" placeholder="管理员user"
                          disabled></el-input>
              </el-form-item>

              <el-form-item style="margin-bottom: 10px">
                <el-button type="primary" @click="submitForm('addForm')"
                >立即创建</el-button
                >
                <el-button @click="resetForm('addForm')">重置</el-button>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="giveUpAdd()">取 消</el-button>
          <!--          <el-button type="primary" @click="systemDialog = false"-->
          <!--          >保 存</el-button-->
          <!--          >-->
        </span>
      </el-dialog>
    </div>
  </el-main>
</template>

<script>
import checkCoal from "./checkCoal";
import updateCoal from "./updateCoal";
import { isPositiveNum, isPositiveTwoDecimal } from '@/utils/validate'
import {regionData, CodeToText, TextToCode} from 'element-china-area-data';// 地址级联选择器
export default {
  name: "coalManage",
  data() {
    var validateIsPositiveNum = (rule, input, callback) => {
      if (!isPositiveNum(input)) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }
    var validateIsPositiveTwoDecimal = (rule, input, callback) => {
      if (!isPositiveTwoDecimal(input)) {
        callback(new Error('请输入最多两位正小数'))
      } else {
        callback()
      }
    }
    return {
      typeOptions:[
        {
          value: 1,
          label: "boolean"
        },{
          value: 2,
          label: "float"
        },{
          value: 3,
          label: "double"
        },{
          value: 4,
          label: "int"
        },{
          value: 5,
          label: "string"
        }
      ],
      foldImpactOptions: [
        {
          value: '选项1',
          label: '影响很小',
        },
        {
          value: '选项2',
          label: '影响较大',
        },
        {
          value: '选项3',
          label: '影响很大',
        }
      ],
      faultImpactOptions: [
        {
          value: '选项1',
          label: '影响很小',
        },
        {
          value: '选项2',
          label: '影响较大',
        },
        {
          value: '选项3',
          label: '影响很大',
        }
      ],
      trapColumnImpactOptions: [
        {
          value: '选项1',
          label: '影响很小',
        },
        {
          value: '选项2',
          label: '影响较大',
        },
        {
          value: '选项3',
          label: '影响很大',
        }
      ],
      surroundingRockStabilityOptions: [
        {
          value: '选项1',
          label: '围岩稳定，对采掘影响很小',
        },
        {
          value: '选项2',
          label: '围岩稳定，对采掘有一定影响',
        },
        {
          value: '选项3',
          label: '围岩稳定，对采掘影响较大',
        }
      ],
      seamSpontaneousCombustionPropensityOptions: [
        {
          value: '选项1',
          label: '不易自燃层',
        },
        {
          value: '选项2',
          label: '易自燃层',
        },
        {
          value: '选项3',
          label: '极易自燃层',
        }
      ],
      gasGradeOptions: [
        {
          value: '选项1',
          label: '低瓦斯矿井',
        },
        {
          value: '选项2',
          label: '高瓦斯矿井',
        },
        {
          value: '选项3',
          label: '煤与瓦斯突出矿井',
        }
      ],
      mineConditionsHydrogeologicalOptions: [
        {
          value: '选项1',
          label: '水文地质条件简单',
        },
        {
          value: '选项2',
          label: '水文地质条件中等',
        },
        {
          value: '选项3',
          label: '水文地质条件复杂',
        },
        {
          value: '选项4',
          label: '水文地质条件及其复杂',
        }
      ],
      impactPropensityOptions: [
        {
          value: '选项1',
          label: '无冲击',
        },
        {
          value: '选项2',
          label: '弱冲击',
        },
        {
          value: '选项3',
          label: '强冲击',
        }
      ],
      coalDustExplosionRiskOptions: [
        {
          value: '选项1',
          label: '无煤尘爆炸危险性',
        },
        {
          value: '选项2',
          label: '弱煤尘爆炸危险性',
        },
        {
          value: '选项3',
          label: '强煤尘爆炸危险性',
        }
      ],
      heatDamageGradeOptions: [
        {
          value: '选项1',
          label: '无热害矿井',
        },
        {
          value: '选项2',
          label: '一级热害矿井',
        },
        {
          value: '选项3',
          label: '二级热害矿井',
        },
        {
          value: '选项4',
          label: '三级热害矿井',
        }
      ],


      name:'项目管理',
      dataListLoading: false,
      activeIndex: "0",
      coals: [
        {
          name: 111,
        },
        {
          name: 222,
        },
      ],
      checkCoalDialog: false,
      multipleSelection: [],
      systemDialog: false,
      subSystemAlter: false,
      deviceAlter: false,
      subDeviceAlter: false,
      subDeviceFieldAlter: false,
      subSystemDisabled: false,
      deviceDisabled: false,
      subDeviceDisabled: false,
      subDeviceFieldDisabled: false,
      addFormLabelPosition: "top",
      dataTemplate: [
        {
          id: 0,
          value: "", //系统
          remark: "",
          subsystemName: [
            {
              id: 0,
              value: "", //子系统
              remark: "",
              device: [
                {
                  id: 0,
                  value: "", //设备
                  remark: "",
                  subDevice: [
                    {
                      id: 0,
                      value: "", //子设备
                      remark: "",
                      subDeviceField: [
                        {
                          id: 0,
                          value: "", //字段
                          remark: "",
                          type: "",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      addForm: {
        data: [
          {
            id: 0,
            value: "", //系统
            remark: "",
            subsystemName: [
              {
                id: 0,
                value: "", //子系统
                remark: "",
                device: [
                  {
                    id: 0,
                    value: "", //设备
                    remark: "",
                    subDevice: [
                      {
                        id: 0,
                        value: "", //子设备
                        remark: "",
                        subDeviceField: [
                          {
                            id: 0,
                            value: "", //字段
                            remark: "",
                            type: "",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        addSubDeviceSelect: "",
        addSubDeviceOptions: [
          {
            id: 0,
            value: "",
          },
        ],
        addDeviceSelect: "",
        addDeviceOptions: [
          {
            id: 0,
            value: "",
          },
        ],
        addSubSystemSelect: "",
        addSubSystemOptions: [
          {
            id: 0,
            value: "",
          },
        ],
        addSystemSelect: "",
        addSystemOptions: [
          {
            id: 0,
            value: "",
          },
        ],
        coalName: "",
        remark: "",
        subDeviceField: [
          {
            id: 0,
            value: "",
            remark: "",
            type: "",
          },
        ],
        device: [
          {
            id: 0,
            value: "",
            remark: "",
          },
        ],
        subDevice: [
          {
            id: 0,
            value: "",
            remark: "",
          },
        ],
        subsystemName: [
          {
            id: 0,
            value: "",
            remark: "",
          },
        ],
        serviceNum: "",
        disaster: "",
        miningMethod: "",
        quality: "",
        systemName: [
          {
            id: 0,
            value: "",
            remark: "",
          },
        ],
        address: "",
        output: "",
        date: "",
        foldImpact: '',
        faultImpact: '',
        trapColumnImpact: '',
        surroundingRockStability: '',
        seamSpontaneousCombustionPropensity: '',
        gasGrade: '',
        mineConditionsHydrogeological: '',
        impactPropensity: '',
        coalDustExplosionRisk: '',
        heatDamageGrade: ''
      },
      rules: {
        type: [
          {required: this.typeSwitch, message: '请输入数据类型', trigger: 'blur'}
        ],
        coalName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入地址信息", trigger: "blur" },
        ],
        output: [
          { required: true, message: "请输入产量信息", trigger: "blur" },
          { validator: validateIsPositiveTwoDecimal, trigger: 'blur' }
        ],
        quality: [
          { required: true, message: "请输入煤质信息", trigger: "blur" },
        ],
        miningMethod: [
          { required: true, message: "请输入煤层深度", trigger: "blur" },
          { validator: validateIsPositiveTwoDecimal, trigger: 'blur' }
        ],
        disaster: [
          { required: true, message: "请输入煤层倾角", trigger: "blur" },
          { validator: validateIsPositiveTwoDecimal, trigger: 'blur' }
        ],
        serviceNum: [
          { required: true, message: "请输入服务年限", trigger: "blur"},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        remark: [
          { required: true, message: "请输入新系统简介", trigger: "blur" },
        ],
        foldImpact: [
          { required: true, message: "请选择摺曲影响", trigger: "blur" },
        ],
        faultImpact: [
          { required: true, message: "请选择断层影响", trigger: "blur" },
        ],
        trapColumnImpact: [
          { required: true, message: "请选择陷落柱影响", trigger: "blur" },
        ],
        surroundingRockStability: [
          { required: true, message: "请选择围岩稳定性", trigger: "blur" },
        ],
        seamSpontaneousCombustionPropensity: [
          { required: true, message: "请选择煤层自燃倾向性", trigger: "blur" },
        ],
        gasGrade: [
          { required: true, message: "请选择瓦斯等级", trigger: "blur" },
        ],
        mineConditionsHydrogeological: [
          { required: true, message: "请选择矿井水文地质条件", trigger: "blur" },
        ],
        impactPropensity: [
          { required: true, message: "请选择冲击倾向性", trigger: "blur" },
        ],
        coalDustExplosionRisk: [
          { required: true, message: "请选择煤尘爆炸危险性", trigger: "blur" },
        ],
        heatDamageGrade: [
          { required: true, message: "请选择热害等级", trigger: "blur" },
        ]
      },
      coalName: "",
      page: 1, //当前第几页
      size: 10, //当前每页显示数量
      total: 0, //全部数据量

      currOperator: '',
      currOperatorName: '',
      setting: {
        // batchRemove: true,
        advancesearch: true,
        backups:false
      },

      updateVisible: false,

      //addDialogVisible: false,
      coaloptions: [
        {
          value: "选项1",
          label: "王家岭",
        },
        {
          value: "选项2",
          label: "张家岭",
        },
        {
          value: "选项3",
          label: "巴拉素",
        },
      ],
      addressoptions: [
        {
          value: "选项1",
          label: "塌方",
        },
        {
          value: "选项2",
          label: "水害",
        },
        {
          value: "选项3",
          label: "煤尘",
        },
      ],

      coalAddress: "",
      disasterType: "",
      tableData: [
        {
          id: 1,
          name: "王家岭项目",
          systemCount: 3,
          address: "王家岭",
          yield: 10,
          disasterType: "水害",
          remark: "无",
        },
        {
          id: 2,
          name: "小保当项目",
          systemCount: 5,
          address: "小保当",
          yield: 10,
          disasterType: "水害",
          remark: "无",
        },
        {
          id: 3,
          name: "大海则项目",
          systemCount: 2,
          address: "大海则",
          yield: 10,
          disasterType: "水害",
          remark: "无",
        },
        {
          id: 4,
          name: "辉凡项目",
          systemCount: 6,
          address: "辉凡",
          yield: 10,
          disasterType: "水害",
          remark: "无",
        },
      ],
      options: [
        {
          title: "项目总数",
          num: "0",
        },
        {
          title: "系统总数",
          num: "0",
        },
        {
          title: "子系统总数",
          num: "0",
        },
      ],
      areaSelectData: regionData, // options绑定的数据就是引入的 provinceAndCityData
      selectedOptions: [], // 地区选择参数，['省区域码', '市区域码']
      selectedOptionsForQuery: []
    };
  },
  components: {
    checkCoal,
    updateCoal
  },
  mounted() {
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    this.addForm.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.addForm.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    console.log(this.currOperatorName)
    this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.advancesearch = resp.data.advancesearch;
        this.setting.backups = resp.data.backups;
        this.size = resp.data.numberperpage;
      }
    });
    this.initData();

  },
  methods: {
    checkCoal(coalId) {
      this.checkCoalDialog = true;
      this.$nextTick(() => {
        this.$refs.coalInfo.init(coalId);
      });
    },
    giveUpAdd() {
      this.resetForm("addForm");
      this.systemDialog = false;
    },
    deleteMany() {
      this.$confirm(
          "此操作将永久删除【" +
          this.multipleSelection.length +
          "】条记录, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            let ids = "?";
            this.multipleSelection.forEach((item) => {
              ids += "ids=" + item.id + "&";
            });

            this.deleteRequest("/coalManage/deleteCoal/" + ids).then((resp) => {
              if (resp.data) {
                this.initData();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    deleteCoal(id) {
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let ids = "?ids=" + id;
            this.deleteRequest("/coalManage/deleteCoal/" + ids).then((resp) => {
              if (resp.data) {
                this.initData();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(`确定进行创建项目操作?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postRequest("/coalManage/insertCoal", this.addForm).then(
                (resp) => {
                  if (resp.data) {
                    this.initData();
                    this.systemDialog = false;
                    this.activeIndex = "0"
                    this.resetForm("addForm");
                  }
                }
            );
          }).catch(() => {})
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addForm.data = [
        {
          id: 0,
          value: "",
          remark: "",
          subsystemName: [
            {
              id: 0,
              value: "",
              remark: "",
              device: [
                {
                  id: 0,
                  value: "",
                  remark: "",
                  subDevice: [
                    {
                      id: 0,
                      value: "",
                      remark: "",
                      subDeviceField: [
                        {
                          id: 0,
                          value: "",
                          remark: "",
                          type: "",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];
      this.addForm.systemName = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.subsystemName = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.device = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.subDevice = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.subDeviceField = [
        {
          id: 0,
          value: "",
          remark: "",
          type: "",
        },
      ];
      this.addForm.addSystemOptions = [
        {
          id: 0,
          value: "",
        },
      ];
      this.addForm.addSubSystemOptions = [
        {
          id: 0,
          value: "",
        },
      ];
      this.addForm.addDeviceOptions = [
        {
          id: 0,
          value: "",
        },
      ];
      this.addForm.addSubDeviceOptions = [
        {
          id: 0,
          value: "",
        },
      ];
      this.addForm.addSystemOptions[0].id = this.addForm.systemName[0].id;
      this.addForm.addSystemOptions[0].value = this.addForm.systemName[0].value;
      this.addForm.addSystemSelect = "";
      this.subSystemAlter = false;
      this.subSystemDisabled = false;
      this.addForm.addSubSystemOptions[0].id = this.addForm.subsystemName[0].id;
      this.addForm.addSubSystemOptions[0].value =
          this.addForm.subsystemName[0].value;
      this.addForm.addSubSystemSelect = "";
      this.deviceAlter = false;
      this.deviceDisabled = false;
      this.addForm.addDeviceOptions[0].id = this.addForm.device[0].id;
      this.addForm.addDeviceOptions[0].value = this.addForm.device[0].value;
      this.addForm.addDeviceSelect = "";
      this.subDeviceAlter = false;
      this.subDeviceDisabled = false;
      this.addForm.addSubDeviceOptions[0].id = this.addForm.subDevice[0].id;
      this.addForm.addSubDeviceOptions[0].value =
          this.addForm.subDevice[0].value;
      this.addForm.addSubDeviceSelect = "";
      this.subDeviceFieldAlter = false;
      this.subDeviceFieldDisabled = false;
    },
    addSystemName() {
      let data = {
        id: 0,
        value: "",
        remark: "",
        subsystemName: [
          {
            id: 0,
            value: "",
            remark: "",
            device: [
              {
                id: 0,
                value: "",
                remark: "",
                subDevice: [
                  {
                    id: 0,
                    value: "",
                    remark: "",
                    subDeviceField: [
                      {
                        id: 0,
                        value: "",
                        remark: "",
                        type: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
      let id = this.addForm.systemName.length;
      let data1 = {
        id: 0,
        value: "",
        remark: "",
      };
      let data2 = {
        id: id,
        value: data.value,
      };
      data.id = id;
      data1.id = id;
      this.addForm.systemName.push(data1);
      this.addForm.addSystemOptions.push(data2);
      this.addForm.data.push(data);
    },
    addSubsystemName() {
      let data = {
        id: 0,
        value: "",
        remark: "",
        device: [
          {
            id: 0,
            value: "",
            remark: "",
            subDevice: [
              {
                id: 0,
                value: "",
                remark: "",
                subDeviceField: [
                  {
                    id: 0,
                    value: "",
                    remark: "",
                    type: "",
                  },
                ],
              },
            ],
          },
        ],
      };
      let id = this.addForm.subsystemName.length;
      let data1 = {
        id: 0,
        value: "",
        remark: "",
      };
      let data2 = {
        id: id,
        value: data.value,
      };
      data.id = id;
      data1.id = id;
      this.addForm.subsystemName.push(data1);
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          this.addForm.data[i].subsystemName.push(data);
          break;
        }
      }
      this.addForm.addSubSystemOptions.push(data2);
    },
    addDevice() {
      let data = {
        id: 0,
        value: "",
        remark: "",
        subDevice: [
          {
            id: 0,
            value: "",
            remark: "",
            subDeviceField: [
              {
                id: 0,
                value: "",
                remark: "",
                type: "",
              },
            ],
          },
        ],
      };
      let id = this.addForm.device.length;
      let data1 = {
        id: 0,
        value: "",
        remark: "",
      };
      let data2 = {
        id: id,
        value: data.value,
      };
      data.id = id;
      data1.id = id;
      this.addForm.device.push(data1);
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (
                this.addForm.addSubSystemSelect ===
                this.addForm.data[i].subsystemName[j].value
            ) {
              this.addForm.data[i].subsystemName[j].device.push(data);
              break;
            }
          }
          break;
        }
      }
      this.addForm.addDeviceOptions.push(data2);
    },
    addSubDevice() {
      let data = {
        id: 0,
        value: "",
        remark: "",
        subDeviceField: [
          {
            id: 0,
            value: "",
            remark: "",
            type: "",
          },
        ],
      };
      let id = this.addForm.subDevice.length;
      let data1 = {
        id: 0,
        value: "",
        remark: "",
      };
      let data2 = {
        id: id,
        value: data.value,
      };
      data.id = id;
      data1.id = id;
      this.addForm.subDevice.push(data1);
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (
                this.addForm.addSubSystemSelect ===
                this.addForm.data[i].subsystemName[j].value
            ) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    this.addForm.addDeviceSelect ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  this.addForm.data[i].subsystemName[j].device[
                      k
                      ].subDevice.push(data);
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.addSubDeviceOptions.push(data2);
    },
    addSubDeviceField() {
      let data = {
        id: 0,
        value: "",
        remark: "",
        type: "",
      };
      let id = this.addForm.subDeviceField.length;
      data.id = id;
      this.addForm.subDeviceField.push(data);
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (
                this.addForm.addSubSystemSelect ===
                this.addForm.data[i].subsystemName[j].value
            ) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    this.addForm.addDeviceSelect ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  for (
                      let l = 0;
                      l <
                      this.addForm.data[i].subsystemName[j].device[k].subDevice
                          .length;
                      l++
                  ) {
                    if (
                        this.addForm.addSubDeviceSelect ===
                        this.addForm.data[i].subsystemName[j].device[k].subDevice[
                            l
                            ].value
                    ) {
                      this.addForm.data[i].subsystemName[j].device[k].subDevice[
                          l
                          ].subDeviceField.push(data);
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    updateSystemOptions(id, value, remark) {
      for (let i = 0; i < this.addForm.addSystemOptions.length; i++) {
        if (id === this.addForm.addSystemOptions[i].id) {
          this.addForm.addSystemOptions[i].value = value;
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (id === this.addForm.data[i].id) {
          this.addForm.data[i].value = value;
          this.addForm.data[i].remark = remark;
          break;
        }
      }
      console.log(this.addForm.data)
    },
    systemChange(system) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          if (this.addForm.data[i].subsystemName.length === 1) {
            this.addForm.subsystemName = [
              {
                id: this.addForm.data[i].subsystemName[0].id,
                value: this.addForm.data[i].subsystemName[0].value,
                remark: this.addForm.data[i].subsystemName[0].remark,
              },
            ];
          } else {
            this.addForm.subsystemName = [];
            for (
                let m = 0;
                m < this.addForm.data[i].subsystemName.length;
                m++
            ) {
              let data = {
                id: this.addForm.data[i].subsystemName[m].id,
                value: this.addForm.data[i].subsystemName[m].value,
                remark: this.addForm.data[i].subsystemName[m].remark,
              };
              this.addForm.subsystemName.push(data);
            }
          }
          break;
        }
      }
      this.addForm.addSubSystemOptions = [];
      this.addForm.addSubSystemSelect = "";
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            let data = {
              id: this.addForm.data[i].subsystemName[j].id,
              value: this.addForm.data[i].subsystemName[j].value,
            };
            this.addForm.addSubSystemOptions.push(data);
          }
          break;
        }
      }
      this.addForm.addDeviceSelect = "";
      this.addForm.addDeviceOptions = [
        {
          id: 0,
          value: "",
        },
      ];
      this.addForm.addSubDeviceSelect = "";
      this.addForm.addSubDeviceOptions = [
        {
          id: 0,
          value: "",
        },
      ];
      this.addForm.device = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.subDevice = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.subDeviceField = [
        {
          id: 0,
          value: "",
          remark: "",
          type: "",
        },
      ];
      this.subSystemAlter = false;
      this.subSystemDisabled = false;
    },
    subSystemFocus() {
      if (this.addForm.addSystemSelect === "") {
        this.subSystemAlter = true;
        this.subSystemDisabled = true;
      }
    },
    updateSubSystemOptions(id, value, remark) {
      for (let i = 0; i < this.addForm.addSubSystemOptions.length; i++) {
        if (id === this.addForm.addSubSystemOptions[i].id) {
          this.addForm.addSubSystemOptions[i].value = value;
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (id === this.addForm.data[i].subsystemName[j].id) {
              this.addForm.data[i].subsystemName[j].value = value;
              this.addForm.data[i].subsystemName[j].remark = remark;
              break;
            }
          }
          break;
        }
      }
    },
    subSystemChange(system, subSystem) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              if (this.addForm.data[i].subsystemName[j].device.length === 1) {
                this.addForm.device = [
                  {
                    id: this.addForm.data[i].subsystemName[j].device[0].id,
                    value:
                    this.addForm.data[i].subsystemName[j].device[0].value,
                    remark:
                    this.addForm.data[i].subsystemName[j].device[0].remark,
                  },
                ];
              } else {
                this.addForm.device = [];
                for (
                    let m = 0;
                    m < this.addForm.data[i].subsystemName[j].device.length;
                    m++
                ) {
                  let data = {
                    id: this.addForm.data[i].subsystemName[j].device[m].id,
                    value:
                    this.addForm.data[i].subsystemName[j].device[m].value,
                    remark:
                    this.addForm.data[i].subsystemName[j].device[m].remark,
                  };
                  this.addForm.device.push(data);
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.addDeviceOptions = [];
      this.addForm.addDeviceSelect = "";
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                let data = {
                  id: this.addForm.data[i].subsystemName[j].device[k].id,
                  value: this.addForm.data[i].subsystemName[j].device[k].value,
                };
                this.addForm.addDeviceOptions.push(data);
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.subDevice = [
        {
          id: 0,
          value: "",
          remark: "",
        },
      ];
      this.addForm.subDeviceField = [
        {
          id: 0,
          value: "",
          remark: "",
          type: "",
        },
      ];
      this.deviceAlter = false;
      this.deviceDisabled = false;
    },
    deviceFocus() {
      if (this.addForm.addSubSystemSelect === "") {
        this.deviceAlter = true;
        this.deviceDisabled = true;
      }
    },
    updateDeviceOptions(id, value, remark) {
      for (let i = 0; i < this.addForm.addDeviceOptions.length; i++) {
        if (id === this.addForm.addDeviceOptions[i].id) {
          this.addForm.addDeviceOptions[i].value = value;
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (
                this.addForm.addSubSystemSelect ===
                this.addForm.data[i].subsystemName[j].value
            ) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (id === this.addForm.data[i].subsystemName[j].device[k].id) {
                  this.addForm.data[i].subsystemName[j].device[k].value = value;
                  this.addForm.data[i].subsystemName[j].device[k].remark = remark;
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    deviceChange(system, subSystem, device) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    device ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  if (
                      this.addForm.data[i].subsystemName[j].device[k].subDevice
                          .length === 1
                  ) {
                    this.addForm.subDevice = [
                      {
                        id: this.addForm.data[i].subsystemName[j].device[k]
                            .subDevice[0].id,
                        value:
                        this.addForm.data[i].subsystemName[j].device[k]
                            .subDevice[0].value,
                        remark:
                        this.addForm.data[i].subsystemName[j].device[k]
                            .subDevice[0].remark,
                      },
                    ];
                  } else {
                    this.addForm.subDevice = [];
                    for (
                        let m = 0;
                        m <
                        this.addForm.data[i].subsystemName[j].device[k].subDevice
                            .length;
                        m++
                    ) {
                      let data = {
                        id: this.addForm.data[i].subsystemName[j].device[k]
                            .subDevice[m].id,
                        value:
                        this.addForm.data[i].subsystemName[j].device[k]
                            .subDevice[m].value,
                        remark:
                        this.addForm.data[i].subsystemName[j].device[k]
                            .subDevice[m].remark,
                      };
                      this.addForm.subDevice.push(data);
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.addSubDeviceOptions = [];
      this.addForm.addSubDeviceSelect = "";
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    device ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  for (
                      let l = 0;
                      l <
                      this.addForm.data[i].subsystemName[j].device[k].subDevice
                          .length;
                      l++
                  ) {
                    let data = {
                      id: this.addForm.data[i].subsystemName[j].device[k]
                          .subDevice[l].id,
                      value:
                      this.addForm.data[i].subsystemName[j].device[k]
                          .subDevice[l].value,
                    };
                    this.addForm.addSubDeviceOptions.push(data);
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.subDeviceField = [
        {
          id: 0,
          value: "",
          remark: "",
          type: "",
        },
      ];
      this.subDeviceAlter = false;
      this.subDeviceDisabled = false;
    },
    subDeviceFocus() {
      if (this.addForm.addDeviceSelect === "") {
        this.subDeviceAlter = true;
        this.subDeviceDisabled = true;
      }
    },
    updateSubDeviceOptions(id, value, remark) {
      for (let i = 0; i < this.addForm.addSubDeviceOptions.length; i++) {
        if (id === this.addForm.addSubDeviceOptions[i].id) {
          this.addForm.addSubDeviceOptions[i].value = value;
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (
                this.addForm.addSubSystemSelect ===
                this.addForm.data[i].subsystemName[j].value
            ) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    this.addForm.addDeviceSelect ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  for (
                      let l = 0;
                      l <
                      this.addForm.data[i].subsystemName[j].device[k].subDevice
                          .length;
                      l++
                  ) {
                    if (
                        id ===
                        this.addForm.data[i].subsystemName[j].device[k].subDevice[
                            l
                            ].id
                    ) {
                      this.addForm.data[i].subsystemName[j].device[k].subDevice[
                          l
                          ].value = value;
                      this.addForm.data[i].subsystemName[j].device[k].subDevice[
                          l
                          ].remark = remark;
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    subDeviceChange(system, subSystem, device, subDevice) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    device ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  for (
                      let l = 0;
                      l <
                      this.addForm.data[i].subsystemName[j].device[k].subDevice
                          .length;
                      l++
                  ) {
                    if (
                        subDevice ===
                        this.addForm.data[i].subsystemName[j].device[k].subDevice[
                            l
                            ].value
                    ) {
                      if (
                          this.addForm.data[i].subsystemName[j].device[k]
                              .subDevice[l].subDeviceField.length === 1
                      ) {
                        this.addForm.subDeviceField = [
                          {
                            id: this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[0].id,
                            value:
                            this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[0].value,
                            remark:
                            this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[0].remark,
                            type:
                            this.addForm.data[i].subsystemName[j].device[k]
                                    .subDevice[l].subDeviceField[0].type,
                          },
                        ];
                      } else {
                        this.addForm.subDeviceField = [];
                        for (
                            let m = 0;
                            m <
                            this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField.length;
                            m++
                        ) {
                          let data = {
                            id: this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[m].id,
                            value:
                            this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[m].value,
                            remark:
                            this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[m].remark,
                            type:
                            this.addForm.data[i].subsystemName[j].device[k]
                                    .subDevice[l].subDeviceField[m].type,
                          };
                          this.addForm.subDeviceField.push(data);
                        }
                      }
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.subDeviceFieldAlter = false;
      this.subDeviceFieldDisabled = false;
    },
    subDeviceFieldFocus() {
      if (this.addForm.addSubDeviceSelect === "") {
        this.subDeviceFieldAlter = true;
        this.subDeviceFieldDisabled = true;
      }
    },
    //传值新增type
    updateSubDeviceFieldOptions(id, value, remark,type) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (
                this.addForm.addSubSystemSelect ===
                this.addForm.data[i].subsystemName[j].value
            ) {
              for (
                  let k = 0;
                  k < this.addForm.data[i].subsystemName[j].device.length;
                  k++
              ) {
                if (
                    this.addForm.addDeviceSelect ===
                    this.addForm.data[i].subsystemName[j].device[k].value
                ) {
                  for (
                      let l = 0;
                      l <
                      this.addForm.data[i].subsystemName[j].device[k].subDevice
                          .length;
                      l++
                  ) {
                    if (
                        this.addForm.addSubDeviceSelect ===
                        this.addForm.data[i].subsystemName[j].device[k].subDevice[
                            l
                            ].value
                    ) {
                      for (
                          let m = 0;
                          m <
                          this.addForm.data[i].subsystemName[j].device[k]
                              .subDevice[l].subDeviceField.length;
                          l++
                      ) {
                        if (
                            id ===
                            this.addForm.data[i].subsystemName[j].device[k]
                                .subDevice[l].subDeviceField[m].id
                        ) {
                          this.addForm.data[i].subsystemName[j].device[
                              k
                              ].subDevice[l].subDeviceField[m].value = value;
                          this.addForm.data[i].subsystemName[j].device[
                              k
                              ].subDevice[l].subDeviceField[m].remark = remark;
                          //新增的
                          this.addForm.data[i].subsystemName[j].device[
                               k
                               ].subDevice[l].subDeviceField[m].type = type;
                          break;
                        }
                      }
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },

    queryByName() {
      this.coalAddress = "";
      this.disasterType = "";
      this.getRequest(
          "/coalManage/queryByCondition/?page=" + this.page + "&size=" + this.size + "&coalName=" + this.coalName
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
        }
      });
    },
    queryByCondition() {
      this.dataListLoading = true
      this.getRequest(
          "/coalManage/queryByCondition/?page=" + this.page + "&size=" + this.size + "&coalName=" + this.coalName
          + "&address=" +
          this.coalAddress +
          "&disasterType=" +
          this.disasterType
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
        }
        this.dataListLoading = false
      });
    },
    initData() {
      this.dataListLoading = true
      this.getRequest(
          "/coalManage/getCoalInfoByPage/?page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
        }
        this.dataListLoading = false
      });
      this.getRequest("/coalManage/getCoalOverview").then((resp) => {
        if (resp) {
          this.options[0].num = resp.data.coalCount + "个";
          this.options[1].num = resp.data.systemCount + "个";
          this.options[2].num = resp.data.subSystemCount + "个";
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    currentChange(currentPage) {
      this.page = currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    updateHandle (row) {
      this.updateVisible = true
      this.$nextTick(() => {
        this.$refs.updateCoal.init(row)
      })
    },
    handleChange() {
      this.addForm.address = CodeToText[this.selectedOptions[0]] + ' ' + CodeToText[this.selectedOptions[1]] + ' ' + CodeToText[this.selectedOptions[2]]
    },
    handleChangeForQuery() {
      if (this.selectedOptionsForQuery[0] !== undefined) {
        this.coalAddress = CodeToText[this.selectedOptionsForQuery[0]] + ' ' + CodeToText[this.selectedOptionsForQuery[1]] + ' ' + CodeToText[this.selectedOptionsForQuery[2]]

      } else {
        this.coalAddress = ''
      }
    }
  },
};
</script>

<style scoped>
.el-form {
  margin-top: 12px;
}

.coalblock {
  /* width: 115px;
  height: 77px;
  border: solid 1px #dcdfe6;
  padding-top: 20px;
  margin-left: 25px;
  text-align: center;
  display: inline-block; */
  text-align: center;
  display: inline-block;
  padding: 3px 5px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #ffffff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #ffffff;
}
.el-step__title {
  font-size: 12px;
}
.el-steps {
  margin-bottom: 6px;
}
</style>
